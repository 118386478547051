import React from "react";
import styled from "styled-components";
import { Text } from "../../../../Components/Components";

const Parents = styled.div`
  width: 66%;
  /* height: 10%; */
  box-sizing: border-box;
  padding-top: 2em;
`;
const TopInfo = styled.div`
  margin-bottom: 1.6%;
`;
const BottomInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BottomChild = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 1390px) {
    &:nth-last-child(1) {
      width: 80%;
    }
  }
`;
const Container = styled.div`
  line-height: 1.2em;
  display: flex;
`;

const TopContent = () => {
  return (
    <Parents>
      <TopInfo>
        <Text color="#2F80ED" bold="bold" size="1.3em" margin="0 0 5% 0">
          CONTACT US
        </Text>
      </TopInfo>
      <BottomInfo>
        <BottomChild>
          <Container>
            <Text bold="bold" margin="0 1em 0 0" size="0.9em">
              찾아오시는 길
            </Text>
          </Container>
          <Container>
            <Text size="0.9em" lineHeight="1.3em">
            (54913) 전북특별자치도 전주시 덕진구 안골4길 16-5 <br/>
            1층 101호(인후동1가, 수지빌딩)
            </Text>
          </Container>
        </BottomChild>
        <BottomChild>
          <Container>
            <Text bold="bold" margin="0 1em 0 2em" size="0.9em">
              문의하기
            </Text>
          </Container>
          <Container>
            <Text color="#2F80ED" bold="bold" size="0.9em" lineHeight="1.3em">
              medieng@medieng.com
              <br />
              063) 231-5004
            </Text>
          </Container>
        </BottomChild>
      </BottomInfo>
    </Parents>
  );
};
export default TopContent;
