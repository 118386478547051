import React, { useEffect } from "react";
import styled from "styled-components";
import { Map, MapMarker, CustomOverlayMap } from "react-kakao-maps-sdk";
import { Text } from "../../../../Components/Components";
import { useGetMobile, check_Tablet, is_Mobile } from "../../../../Util/Util";

const Parents = styled(Map)`
  width: ${(e) => (e.mobile ? "90%" : "66%")};
  height: ${(e) =>
    e.mobile
      ? "calc(var(--vh, 1vh)*30)"
      : check_Tablet()
      ? "calc(var(--vh, 1vh)*45)"
      : "calc(var(--vh, 1vh)*55)"};
  margin: 2% 0;
  box-sizing: border-box;
  border-radius: 10px;
  /* @media screen and (max-height: 785px) {
    height: calc(var(--vh, 1vh) * 35) !important;
  } */
  @media screen and (max-width: 1390px) {
    height: calc(var(--vh, 1vh) * 45);
  }
`;
const Marker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const TextContents = styled.div`
  border-radius: 5px;
  background: white;
  padding: 5px 10px;
`;
const Image = styled.img`
  width: 40px;
  height: 40px;
`;
const IEParents = styled.div`
  width: 66%;
  height: 50%;
  border-radius: 10px;
`;

export const KaKaoMap = ({ name }) => {
  return (
    <Parents
      center={{ lat: 35.841175, lng: 127.156768 }}
      mobile={is_Mobile()}
      level={useGetMobile() ? 4 : 3}
    >
      <CustomOverlayMap
        position={{ lat: 35.841175, lng: 127.156768 }}
      >
        <Marker>
          <TextContents>
            <Text size="0.9em">{name}</Text>
          </TextContents>
          <Image src={require("../../image/location.svg").default} />
        </Marker>
      </CustomOverlayMap>
    </Parents>
  );
};

export const IEKaKaoMap = ({ name }) => {
  const { kakao } = window;

  useEffect(() => {
    Maps();
  }, []);

  window.addEventListener("resize", () => {
    Maps();
  });

  const Maps = () => {
    let container = document.getElementById("iemap");
    let options = {
      center: new kakao.maps.LatLng(35.841175, 127.156768), // 새로운 중심 좌표
      level: 3,
    };
    let map = new kakao.maps.Map(container, options);

    var markerImage = new kakao.maps.MarkerImage(
      require("../../image/location.svg").default,
      new kakao.maps.Size(50, 50),
      new kakao.maps.Point(27, 69)
    );
    
    let markerPosition = new kakao.maps.LatLng(35.841175, 127.156768); // 새로운 마커 위치
    let marker = new kakao.maps.Marker({
      position: markerPosition,
      image: markerImage,
    });
    
    marker.setMap(map); // 마커를 지도에 추가
  };
};
